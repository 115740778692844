import React, { Component } from 'react';
import ReadAloud from './ReadAloud.js';
import { unzip } from 'unzipit';
import { PanelGroup, PanelResizeHandle, Panel } from 'react-resizable-panels';
import CoreWorkers from '../workers/CoreWorkers';
import { Calendar } from './Calendar';
// import Frame, { FrameContextConsumer } from 'react-frame-component';
// import styles from '!!raw-loader!./iframe.css';
// import Markdown from 'react-markdown'
// import remarkGfm from 'remark-gfm'

export default class Application extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        window.application = this;
        this.core = new CoreWorkers();
    }

    componentWillUnmount() {
        if (window.application === this) {
            delete window.application;
        }
    }
    render() {
        let isMobile = window.util.isMobile();
        return <div style={{ display: 'flex', position: 'relative', inset: 0, flex: 1, overflow: 'scroll hidden', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', }}>
            <PanelGroup direction="horizontal" style={{
                alignSelf: 'stretch', height: 'unset',
                ...(isMobile ? { justifyContent: 'flex-start', overflow: 'scroll hidden', scrollSnapType: 'x mandatory' } : {})
            }}>
                <Panel id="repository" order={0}  defaultSize={15} minSize={isMobile ? 100 : 15} style={{ alignSelf: 'stretch',  background: 'var(--bg)', ...(isMobile ? { flexShrink: 0, flexBasis: '100%', scrollSnapAlign: 'center', scrollSnapStop: 'always', } : {}) }}>
                    <Repository p={this} />
                </Panel>
                {this.state.file || !isMobile ? <React.Fragment>
                    <PanelResizeHandle id="resize_handle" style={{ verticalAlign: 'center', textAlign: 'center', alignSelf: 'stretch', width: '1px', background: 'var(--grey)', zIndex: 10 }} >
                    <div style={{ position: 'absolute', bottom: '10%', left: -17.5, background: 'var(--bg)', flexDirection: 'row', gap: 10, borderRadius: 15 }}>
                        <div style={{ width: 35, height: 35, background: 'var(--grey)', borderRadius: 20, cursor: 'grab', border: '2px solid var(--txti)' }}>
                            <i className='material-icons nohover' style={{ color: 'var(--txti)', cursor: 'grab', }}> menu</i>
                        </div>
                    </div>

                </PanelResizeHandle>
                <Panel id="Document" order={1} defaultSize={55} minSize={isMobile ? 100 : 50} style={{ alignSelf: 'stretch', ...(isMobile ? { flexShrink: 0, flexBasis: '100%', scrollSnapAlign: 'center', scrollSnapStop: 'always', } : {}) }}>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', scrollSnapType: 'x mandatory', overflow: 'scroll hidden', background: 'var(--bg)', justifyContent: 'flex-start' }}>
                        {this.state.file ? <React.Fragment>
                            <Document doc={this.state.file} />
                        </React.Fragment> : null}
                    </div>
                </Panel>
                </React.Fragment> : null}
               
                <PanelResizeHandle id="resize_handle_2" style={{ verticalAlign: 'center', textAlign: 'center', alignSelf: 'stretch', width: '1px', background: 'transparent', zIndex: 10 }} >
                    <div style={{ position: 'absolute', bottom: '10%', left: -17.5, background: 'var(--bg)', flexDirection: 'row', gap: 10, borderRadius: 15 }}>
                        <div style={{ width: 35, height: 35, background: 'var(--grey)', borderRadius: 20, cursor: 'grab', border: '2px solid var(--txti)' }}>
                            <i className='material-icons nohover' style={{ color: 'var(--txti)', cursor: 'grab', }}> calendar_month</i>
                        </div>
                    </div>

                </PanelResizeHandle>
                <Panel id="Calendar" order={2} defaultSize={20} minSize={isMobile ? 100 : 20} style={{ alignSelf: 'stretch', ...(isMobile ? { flexShrink: 0, flexBasis: '80%', scrollSnapAlign: 'center', scrollSnapStop: 'always', } : {}) }}>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', scrollSnapType: 'x mandatory', overflow: 'hidden', background: 'var(--bg)', justifyContent: 'flex-start' }}>
                        <div style={{ position: 'relative', scrollSnapAlign: 'center', scrollSnapStop: 'always', flexShrink: 0, backgroundColor: 'var(--bg)', borderRadius: '10px', margin: '5px', justifyContent: 'flex-start', overflow: 'hidden', padding: 5, width: '100%', gap: 10, boxShadow: 'var(--neoi)' }}>
                            <Calendar p={this} app={this} leafs={[]} />
                        </div>
                    </div>
                </Panel>
            </PanelGroup>
        </div >;
    }
}

class Repository extends React.Component {
    constructor(props) {
        super(props);
        this.state = { files: [] };
    }
    componentDidMount() {
        this.subscription = window.app.core.worker.subscribe({ type: 'files' }, (files) => {
            console.log(files);
            this.setState({ files });
        })
    }
    componentWillUnmount() {
        if (this.subscription) this.subscription.unsubscribe();
    }
    addfile = async (e) => {
        let file = e.target.files[0];
        if (!file) return;
        // this.props.p.setState({ file });
        // let blob = new Blob(await file.arrayBuffer());
        let buffer = await (file.arrayBuffer())
        window.app.core.worker.request({ request: 'add_file', file: buffer, name: file.name }, [buffer]).then(store => {
            this.props.p.setState({ file: store });
        })
    }
    selecfile = (name) => {
        window.app.core.worker.request({ request: 'select_file', name: name }).then(store => {
            this.props.p.setState({ file: store });
        })
    }
    render() {
        return <div style={{ flex: 1, padding: '10px 0px' }}>
            <div style={{ flex: 1, gap: 25, justifyContent: 'flex-start', padding: 10, overflow: 'hidden scroll' }}>
                {this.state.files.map((file,i) => {
                    return <div style={{ border: '0.5px solid #adadad', justifyContent: 'flex-start', alignItems: 'flex-start', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap', padding: '0px 5px', cursor: 'pointer', textAlign: 'left', background: 'var(--fg)', padding: 5, fontSize: 16, borderRadius: 10 }}
                        onClick={(e) => {
                            this.selecfile(file);
                        }}
                    >{i+1}. {file}</div>
                })}
            </div>
            <div style={{ height: 50, flexDirection: 'row', justifyContent: 'flex-end', gap: 10, background: 'var(--fg)', padding: 5 }}>
                <input type='file' id='file' ref={this.input} style={{ display: 'flex', alignSelf: 'stretch', lineHeight: '40px', flexShrink: 0, flex: 1, color: 'white', }}
                    onChange={this.addfile}
                />
                <i className='material-icons'>add</i>
            </div>
        </div>;
    }
}

class Document extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.iframe = React.createRef();
    }
    render() {
        return <React.Fragment>
            <div style={{ display: 'flex', position: 'relative', flexDirection: 'row', scrollSnapAlign: 'center', scrollSnapStop: 'always', flexShrink: 0, width: window.innerWidth, maxWidth: 600, borderRadius: '5px', margin: '5px auto', boxShadow: 'var(--neo)' }}>
                <div id='book' style={{ display: 'flex', position: 'absolute', inset: 0, }}>
                    <div style={{ display: 'flex', position: 'absolute', inset: 0, flexDirection: 'column', overflow: 'hidden scroll', zIndex: 0, justifyContent: 'flex-start', alignItems: 'flex-start', padding: 30, textAlign: 'justify' }}>
                        <iframe ref={this.iframe} key={'book'} title={'book'} src={this.props.doc} width={window.innerWidth < 500 ? '100%' : '50%'} height={'100%'} style={{ display: 'flex', flexShrink: 0, width: '100%', maxWidth: 600, height: '100%' }} frameBorder="0"
                            onLoad={(e) => {
                                let doc = this.iframe.current.contentWindow.document;
                                let target = doc.getElementById('infinitescroller');
                                doc.onselectionchange = (e) => {

                                    let sel = doc.getSelection();
                                    if (sel.type !== 'None' && target.contains(sel.anchorNode)) {
                                        window.readaloud.setCurrent({
                                            focusNode: sel.focusNode,
                                            focusOffset: sel.focusOffset
                                        })
                                        window.readaloud.focusNode(sel.focusNode);
                                    } else {

                                    }
                                }
                            }}
                        />
                    
                    </div>
                </div>
                <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, background: 'var(--bg)', zIndex:1 }}>
                  <ReadAloud iframe={this.iframe} />
                </div>
            </div>
         
        </React.Fragment>;
    }
}

    
                        {/* <Markdown remarkPlugins={[remarkGfm]}>{this.props.doc}</Markdown> */}

// class Book extends Component {
//     constructor(props) {
//         super(props)
//         this.state = {};
//         window.book = this;
//         this.unzipepub(this.props.file);
//         this.iframe = React.createRef();
//     }
//     componentWillUnmount() {
//         if (window.book === this) {
//             delete window.book
//         }
//     }
//     unzipepub = async (file) => {
//         if (file) {
//             this.store = await unzip(file);
//             this.organize();

//         }
//     }
//     organize = async () => {
//         let parser = new DOMParser();
//         this.store.html = parser.parseFromString(`<html>
//       <head>
//       <style>
//       body, html {
//         overflow: hidden;
//         cursor: auto;
//         overscroll-behavior: contain;
//         font-family: avenir, Arial, Helvetica, sans-serif;
//         scrollbar-width: none;
//         height: 100vh;
//         width: 100vw;
//         margin: 0;
//         padding: 20;
//       }
//       div {
//         max-width: 100%;
//       }
//       </style>
//       </head><body style="display:flex; position: relative; box-sizing:border-box; flex-direction:column; justify-content:flex-start; align-items:center; text-align:left;">
//       <div id="infinitescroller" style="position: absolute; inset:0; display: flex; flex-direction: column; width:100%; overflow: hidden scroll; whitespace: nowrap; z-index:1;">
//       <div id="hilite" style="position: absolute; display: flex; background-color: rgba(30, 139, 195, 0.5); user-select: none; pointer-events: none; z-index: 6, visibility: hidden;"></div>
//       </div>
      
//       <body></html>`, "text/html");

//         this.store.order = await this.order();
//         this.store.chapters = this.store.order.map(o => {
//             let ch = this.store.entries[o] || this.store.entries['OEBPS/' + o];
//             return ch;
//         });
//         await this.update(this.store.chapters);

//         this.singlehtml(this.store.chapters);

//         await this.table();
//         this.setState({
//             ready: true
//         })

//     }
//     singlehtml = (order) => {
//         order.forEach(o => {
//             let html = o.fixed;
//             let div = this.store.html.createElement('div');
//             o.title = html.getElementsByTagName('title')[0].innerText;
//             div.id = o.name;
//             div.title = html.getElementsByTagName('title')[0].innerText;
//             div.style.display = 'flex';
//             div.style.position = 'relative';
//             div.style.width = '90%';
//             div.style.flexDirection = 'column';
//             div.style.justifyContent = 'flex-start';
//             div.style.alignItems = 'center';
//             div.style.boxSizing = 'border-box';
//             div.style.textAlign = 'left';
//             div.style.margin = '5px 5%';
//             div.setAttribute('data-duration', this.wc(html))
//             div.append(...html.body.children);
//             this.store.html.body.children[0].appendChild(div);
//         })
//     }
//     wc = (html) => {
//         let count = (txt) => {
//             let words = txt.replace(/[^a-zA-Z\s]/g, '').split(/[\s]+/);
//             return words.length;
//         }
//         let c = count(html.body.innerText);
//         return Math.floor(c / 200);
//     }
//     update = (order) => {

//         return Promise.all(order.map(async (ch) => {
//             ch.html = (await ch.text()).replaceAll('\\"', '"');
//             let parser = new DOMParser();
//             let html = parser.parseFromString(ch.html, "text/html");



//             html.body.style.width = '100%';
//             html.body.style.padding = '20px';
//             html.body.style.boxSizing = 'border-box';
//             html.body.style.display = 'flex';
//             html.body.style.flexDirection = 'column';
//             html.body.style.justifyContent = 'flex-start';
//             html.body.style.alignItems = 'center';


//             // let links = html.documentElement.getElementsByTagName('link');
//             // await Promise.all(Array.from(links).map(async (l) => {
//             //   let href = l.href.replace('http://localhost:3001/', '');
//             //   let ze = this.store.entries['OEBPS/' + href] || this.store.entries[href]
//             //   if (ze) {
//             //     if (!ze.url) {
//             //       let blob = await ze.blob();
//             //       ze.url = URL.createObjectURL(blob);
//             //     }
//             //     l.href = ze.url;
//             //   };
//             // }))

//             let imgs = html.documentElement.getElementsByTagName('img');
//             await Promise.all(Array.from(imgs).map(async (l) => {
//                 let url = window.location.href
//                 let href = l.src.replace(url, '');

//                 let ze = this.store.entries['OEBPS/' + href] || this.store.entries[href]
//                 if (ze) {
//                     if (!ze.url) {
//                         let blob = await ze.blob();
//                         ze.durl = await this.blobToDataURL(blob)
//                         ze.url = URL.createObjectURL(blob);
//                     }
//                     l.src = ze.url;
//                 };
//                 l.maxWidth = '95%'
//                 l.style.maxWidth = '95%';
//             }))

//             ch.fixed = html
//             return true;
//         }))
//     }
//     blobToDataURL = (blob) => {
//         return new Promise((resolve, reject) => {
//             var a = new FileReader();
//             a.onload = (e) => { resolve(e.target.result); }
//             a.readAsDataURL(blob);
//         });
//     }
//     order = async () => {
//         let container = await this.container();
//         let rf = (container.getElementsByTagName('rootfile')[0]).attributes['full-path'].value;
//         let rootfile = await this.container(rf);
//         let manifest = Object.fromEntries(Array.from((rootfile.getElementsByTagName('manifest')[0]).children).map(item => [item.attributes.id.value, item.attributes.href.value]))
//         let spine = (rootfile.getElementsByTagName('spine')[0]);
//         this.store.spine = spine;
//         return Array.from(spine.children).map(itemref => manifest[itemref.attributes.idref.value]);
//     }
//     container = async (name) => {
//         if (!name)
//             name = 'META-INF/container.xml';
//         let xmltext = await this.text(name);
//         let cleaned = xmltext.replaceAll('\\"', '"');
//         let parser = new DOMParser();
//         let xmlDoc = parser.parseFromString(cleaned, "text/xml");
//         return xmlDoc;

//     }
//     text = (filename) => {
//         return this.store.entries[filename].text();
//     }
//     table = async () => {
//         let ncx = Object.values(this.store.entries).find(entry => entry.name.endsWith('.ncx'));
//         let xmltext = await ncx.text();
//         let cleaned = xmltext.replaceAll('\\"', '"');
//         let parser = new DOMParser();
//         let xmlDoc = parser.parseFromString(cleaned, "text/xml");

//         let navMap = xmlDoc.getElementsByTagName('navMap')[0];
//         this.tableof = [];
//         this.nav(navMap, this.tableof)
//         // this.rendertoc(this.tableof);

//         return this.tableof;
//     }
//     nav = (n, o) => {
//         Array.from(n.children).forEach(nc => {
//             if (nc.tagName.toLowerCase() === 'navpoint') {
//                 let np = [];
//                 o.push(np);
//                 this.nav(nc, np);
//             }
//             if (nc.tagName.toLowerCase() === 'navlabel') {
//                 o.label = nc.firstElementChild.innerHTML;

//             }
//             if (nc.tagName.toLowerCase() === 'content') {
//                 o.src = nc.getAttribute('src');
//             }

//         })
//     }
//     render() {
//         if (!this.state.ready) return null;

//         return <React.Fragment>
//             <div style={{ display: 'flex', position: 'relative', flexDirection: 'row', scrollSnapAlign: 'center', scrollSnapStop: 'always', flexShrink: 0, width: 330, borderRadius: '5px', margin: '5px' }}>
//                 <div style={{ display: 'flex', flexDirection: 'column', width: '30px', height: '100%', backgroundColor: 'white', textOrientation: 'sideways' }} >
//                     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', writingMode: 'vertical-rl', transform: 'rotate(180deg)', margin: '10px 0px', backgroundColor: 'orange', padding: '10px 0px', borderRadius: '0px 5px 5px 0px' }} onClick={(e) => { this.setState({ show: 'toc' }) }}>toc</div>
//                     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', writingMode: 'vertical-rl', transform: 'rotate(180deg)', margin: '10px 0px', backgroundColor: 'orange', padding: '10px 0px', borderRadius: '0px 5px 5px 0px' }} onClick={(e) => { this.setState({ show: 'heading' }) }}>headings</div>
//                 </div>
//                 <div style={{ display: 'flex', position: 'relative', flexDirection: 'column', flex: 1, borderRadius: '5px', backgroundColor: 'black', }} >
//                     {this.state.show === 'toc' ? <div id='toc' style={{ display: 'flex', position: 'absolute', inset: 0, }}>
//                         <div style={{ display: 'flex', position: 'absolute', inset: 0, flexDirection: 'column', overflow: 'hidden scroll', zIndex: 0, justifyContent: 'flex-start', alignItems: 'center' }}>
//                             <TOCRender toc={this.tableof} html={this.store.html} book={this} />
//                         </div>
//                     </div> : null}
//                     {this.state.show === 'heading' ? <div id='headings' style={{ display: 'flex', position: 'absolute', inset: 0, }}>
//                         <div style={{ display: 'flex', position: 'absolute', inset: 0, flexDirection: 'column', overflow: 'hidden scroll', zIndex: 0, justifyContent: 'flex-start', alignItems: 'center' }}>
//                             <HeadingsRender html={this.store.html} book={this} />
//                         </div>
//                     </div> : null}
//                 </div>

//             </div>
//             <div style={{ display: 'flex', position: 'relative', flexDirection: 'row', scrollSnapAlign: 'center', scrollSnapStop: 'always', flexShrink: 0, width: window.innerWidth - 30, maxWidth: 600, borderRadius: '5px', margin: '5px auto', border: '1px solid black' }}>
//                 <div id='book' style={{ display: 'flex', position: 'absolute', inset: 0, }}>
//                     <div style={{ display: 'flex', position: 'absolute', inset: 0, flexDirection: 'column', overflow: 'hidden scroll', zIndex: 0, justifyContent: 'flex-start', alignItems: 'center' }}>
//                         <BookRender html={this.store.html} book={this} iframe={this.iframe} />
//                     </div>
//                 </div>
//             </div>
//             <ReadAloud iframe={this.iframe} />
//         </React.Fragment>
//     }
// }

// class BookRender extends Component {

//     constructor(props) {
//         super(props);
//         this.state = {};

//     }
//     componentDidMount() {

//     }
//     render() {
//         if (this.props.html) {

//             return <iframe ref={this.props.iframe} key={'book'} title={'book'} srcDoc={this.props.html.documentElement.outerHTML} width={window.innerWidth < 500 ? '100%' : '50%'} height={'100%'} style={{ display: 'flex', flexShrink: 0, width: '100%', maxWidth: 600, height: '100%' }} frameBorder="0"
//                 onLoad={(e) => {
//                     let doc = this.props.iframe.current.contentWindow.document;
//                     let target = doc.getElementById('infinitescroller');
//                     doc.onselectionchange = (e) => {

//                         let sel = doc.getSelection();
//                         if (sel.type !== 'None' && target.contains(sel.anchorNode)) {
//                             window.rb.setCurrent({
//                                 focusNode: sel.focusNode,
//                                 focusOffset: sel.focusOffset
//                             })
//                         } else {

//                         }
//                     }
//                 }}
//             />
//         }
//     }

// }
// class TOCRender extends Component {

//     constructor(props) {
//         super(props);
//         this.state = {};
//     }
//     scrollto = (e) => {
//         try {
//             let src = e.target.id;
//             let doc = this.props.book.iframe.current.contentWindow.document;
//             let el = doc.getElementById(src) || doc.getElementById('OEBPS/' + src);
//             el.scrollIntoView({ behavior: 'smooth' });
//         } catch (error) {
//             console.log(error);
//         }
//     }
//     wc = (src) => {
//         let body = this.props.html;
//         let el = body.getElementById(src) || body.getElementById('OEBPS/' + src);
//         let count = (txt) => {
//             let words = txt.replace(/[^a-zA-Z\s]/g, '').split(/[\s]+/);
//             return words.length;
//         }
//         let c = count(el.innerText);
//         return Math.floor(c / 200);
//     }
//     content = (toc, lvl) => {
//         return toc.map(c => {
//             return <div style={{ display: 'flex', position: 'relative', flexDirection: 'column', margin: '5px 0px', color: 'white' }}>
//                 <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
//                     <div id={c.src.split('#')[0]} style={{ display: 'flex', flex: 1, marginLeft: lvl * 10, cursor: 'pointer' }}
//                         onClick={this.scrollto}
//                     >{c.label}</div>
//                     <div style={{ display: 'flex', flexShrink: 0, marginLeft: 'auto', width: '40px', justifyContent: 'flex-end' }} title='min'>{this.wc(c.src.split('#')[0]) || ''}</div>
//                 </div>

//                 {c.length ? this.content(c, lvl + 1) : null}
//             </div>
//         })
//     }
//     render() {

//         return <div style={{ display: 'flex', position: 'relative', flexDirection: 'column', width: '100%', overflow: 'hidden scroll', padding: '0px 15px', fontSize: '12px', boxSizing: 'border-box' }} >
//             {this.content(this.props.toc, 0)}
//         </div>

//     }
// }
// class HeadingsRender extends Component {

//     constructor(props) {
//         super(props);
//         this.state = {};
//     }
//     // scrollto = (e) => {
//     //   try {
//     //     let src = e.target.id;
//     //     let doc = this.props.book.iframe.current.contentWindow.document;
//     //     let el = doc.getElementById(src) || doc.getElementById('OEBPS/' + src);
//     //     el.scrollIntoView({ behavior: 'smooth' });
//     //   } catch (error) {
//     //     console.log(error);
//     //   }

//     // }
//     headings = (html) => {
//         let headings = html.body.querySelectorAll("h1, h2, h3, h4, h5, h6");

//         return Array.from(headings).map(h => {
//             let lvl = Number(h.tagName.substring(1));
//             return <div style={{ display: 'flex', position: 'relative', flexDirection: 'column', margin: '5px', color: 'white' }}>
//                 <div style={{ display: 'flex', flex: 1, marginLeft: lvl * 10, cursor: 'pointer' }}
//                 >{h.innerText}</div>
//             </div>
//         })
//     }
//     render() {

//         return <div style={{ display: 'flex', position: 'relative', flexDirection: 'column', width: '100%', overflow: 'hidden scroll', padding: '0px 10px', boxSizing: 'borderbox', fontSize: '12px' }} >
//             {this.headings(this.props.html)}
//         </div>

//     }

// }