import React, { Component } from 'react';

import './App.css';
import Application from './components/Application';
import Await from './components/Await';
import util from './services/util';

export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = { show: 'toc', mode: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light', };
    this.input = React.createRef();
    this.bookref = React.createRef();
    this.tocref = React.createRef();
    this.headingsref = React.createRef();
    window.app = this;

    this.util = new util();
    window.onresize = (e) => {
      this.width = window.innerWidth;
      window.height = window.innerHeight;
      this.forceUpdate();
    }
    window.matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) => {
        let mode;
        if (e.matches) {
          mode = 'dark'
          this.setState({ mode });
        }
        else {
          mode = 'light'
          this.setState({ mode });
        }
      });
    this.initProtos();
    // this.ra = new readAloud(this);
  }

  initProtos = () => {
    // eslint-disable-next-line no-extend-native
    Array.prototype.move = function (from, to) {
      this.splice(to, 0, this.splice(from, 1)[0]); return this;
    };
    // eslint-disable-next-line no-extend-native
    Array.prototype.lookup = function (from, to, condition) {
      let found;
      if (to === null) to = this.length - 1;
      let ops = from >= to ? () => from-- : () => from++;
      while (found === undefined) {
        if (!this[from]) {
          found = null;
        } else
          if (condition(this[from])) {
            found = this[from];
          } else if (from === to) {
            found = null;
          } else {
            ops();
          }
      }
      return found;
    };
    // eslint-disable-next-line no-extend-native
    Array.prototype.lookupIndex = function (from, to, condition) {
      let found;
      if (to === null) to = this.length - 1;
      let ops = from >= to ? () => from-- : () => from++;
      while (found === undefined) {
        if (!this[from]) {
          found = from;
        } else
          if (condition(this[from])) {
            found = from;
          } else if (from === to) {
            found = null;
          } else {
            ops();
          }
      }
      return found;
    };
    // eslint-disable-next-line no-extend-native
    Array.prototype.last = function () {
      return this[this.length - 1];
    };
    // eslint-disable-next-line no-extend-native
    Array.prototype.setlast = function (value) {
      this[Math.max(this.length - 1, 0)] = value;
    };
    // eslint-disable-next-line no-extend-native
    Array.prototype.remove = function (obj, by) {
      const index = this.findIndex(item => typeof obj === 'function' ? obj(item) : item === obj);
      if (index !== -1) {
        if (by)
          this.splice(index, 1, by);
        else
          this.splice(index, 1);
      }
    };

    // eslint-disable-next-line no-extend-native
    String.prototype.splice = function (
      index,
      howManyToDelete,
      stringToInsert
    ) {
      var characterArray = this.split("");
      Array.prototype.splice.apply(
        characterArray,
        arguments
      );
      return (
        characterArray.join("")
      );
    };
    // eslint-disable-next-line no-extend-native
    String.prototype.reverse = function () {
      var characterArray = this.split("");
      characterArray.reverse();
      return (
        characterArray.join("")
      );
    };
    // eslint-disable-next-line no-extend-native
    HTMLElement.prototype.getNodeIndex = function () {
      // Convert child nodes to an array and find the index of the current element
      return [...this.parentNode.childNodes].indexOf(this);
    };
  }


  render() {

    return <Screen className='App' id="app"
      offline={this.state.offline}
      mode={this.state.mode}
      style={{
        margin: 'auto', justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent', overflow: 'hidden', borderLeft: '0px solid grey',
        minHeight: window.innerHeight, zIndex: 0,
        color: 'var(--txt)',
      }}
    >
      <Application />
      <Await />
    </Screen>
  }
}

class Screen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    window.ironman = this;
    this.screen = [];
    this.scrolling = {};
    this.container = React.createRef();
  }
  componentDidMount() {
    this.gbc = this.container.current.getBoundingClientRect();


  }
  render() {

    return (<div
      ref={this.container}
      id={this.props.id}
      style={this.props.style}
      className={this.props.className}
      data-theme={this.props.mode}

      onClick={(e) => {

        let clicked = window.util.clicked(e.target);
        // console.log(clicked, clicked?.id);
        if (!clicked) return;
        let current = clicked.style.animation || '';
        clicked.style.animation = 'blur 0.5s linear';
        clicked.onanimationend = () => {
          clicked.onanimationend = null;
          clicked.style.animation = current;
        };
        this.clicked = null;
        this.timer = null;
        clearTimeout(this.st);
      }}

    >
      {this.props.children}
      {this.state.showModal && this.modal ? <div translate="no" style={{ position: 'absolute', inset: 0, zIndex: 10, backdropFilter: 'blur(5px)', WebkitBackdropFilter: 'blur(5px)' }}
        onClick={(e) => {
          this.setState({ showModal: false })
        }}
      >
      </div> : null}
      {this.props.offline ? <div style={{ position: 'fixed', inset: 0, zIndex: 10000, pointerEvents: 'none', background: 'transparent' }}>
        <div style={{ position: 'absolute', top: 0, color: 'red', fontWeight: 'bold', fontsize: 12, backdropFilter: 'blur(2px)', borderRadius: 5, padding: '0px 2px' }}>**offline**</div>
      </div> : null}
    </div>);
  }
}


