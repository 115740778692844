import React, { Component } from 'react'


export default class Await extends Component {
    constructor(props) {
        super(props);
        this.state = { show: false, msg: 'Fetching Results' };
        window.app.await = this;
    }
    wait = (msg, wait) => {
        this.setState({ show: true, msg: msg, wait })
        if (wait) {
            return new Promise(resolve => {
                this.resolve = resolve;
                this.to = setTimeout(() => {
                    // console.log('timeout over');
                    this.setState({ wait: null })
                    delete this.resolve;
                    resolve(true);
                }, wait);
            })
        }
    }
    done = () => {
        this.setState({ show: false, msg: 'Fetching Results' })
    }
    render() {
        if (!this.state.show) return null;
        return (<div translate="no" style={{ position: 'fixed', inset: 0, zIndex: 10001, backdropFilter: 'blur(5px)', justifyContent: 'center', alignItems: 'center' }}>
            <div className="authenticating input" style={{ textAlign: 'center' }}>
                <div className='tetrominos'>
                    <div className='tetromino box1'></div>
                    <div className='tetromino box2'></div>
                    <div className='tetromino box3'></div>
                    <div className='tetromino box4'></div>
                </div>
                <div className="loading" style={{ position: 'absolute', bottom: 90, width: '100%', fontSize: 24, fontWeight: 'bold' }}>{this.state.msg}</div>
            </div>
            {this.state.wait ? <button

                style={{ position: 'absolute', bottom: 30, zIndex: 10, background: 'var(--cta)', color: 'white', border: '1px solid white', padding: 10, borderRadius: 10 }}
                ref={(e) => {
                    if (e) {
                        this.current = this.state.wait
                        this.ti = setInterval(() => {
                            this.current -= 1000;
                            e.innerText = `Cancel (${this.current / 1000})`
                            if (!this.current) {
                                clearInterval(this.ti);
                            }
                        }, 1000);
                    }
                }}
                onClick={(e) => {
                    clearTimeout(this.to);
                    if (this.resolve) this.resolve(false);
                    this.setState({ wait: null })
                    delete this.resolve;
                    this.done();
                }}
            >Cancel</button> : null}
        </div>);
    }
}

