import React, { Component } from 'react'

class Calendar extends Component {
    constructor(props) {
        super(props);
        this.state = { showCalendar: false, register: null }
        this.previous = window.util.array(100, 1);
        this.next = window.util.array(100, 1)
        this.years = window.util.array(3, -1);
        this.currentweek = React.createRef();
        this.container = React.createRef();
        this.subscriptions = [];
        if (this.props.when)
            this.setCalendar(this.props.when);
    }
    componentDidMount() {
        this._mounted = true;
        this.scrollIntoView();
    }

    scrollIntoView = () => {
        if (this.currentweek.current && this.container.current)
            window.util.scrollIntoView(this.currentweek.current, this.container.current);
    }
    getWeek = (dt) => {
        let d = new Date(dt || Date.now());
        let y = d.getFullYear();
        let m = d.getMonth();
        let cd = d.getDate();
        return Math.floor((cd + new Date(y, m, 1).getDay()) / 7)
    }
    setCalendar = (dt) => {

        let d = new Date(dt || Date.now());
        let y = d.getFullYear();
        let m = d.getMonth();
        let date = new Date(y, m, d.getDate())
        let state = {
            showCalendar: date,
            week: {
                dt: date,
                gbc: {
                    top: 200
                },
                y: y,
                m: m,
                w: this.getWeek(dt),
                isToday: true
            }
        };
        if (this._mounted)
            this.setState(state)
        else
            Object.assign(this.state, state)
    }
    year = (y) => {
        let d = new Date();
        let cy = d.getFullYear() + y;
        d.setFullYear(cy);
        return <div key={y} style={{ alignSelf: 'stretch', gap: 5, }}>
            {window.util.array(12).map(m => {
                return this.month(cy, m)
            })}
        </div>
    }
    month = (y, m) => {
        let key = y + "-" + (m + 1).toString().padStart(2, '0');
        let month = Object.values(this.props.leafs).find(x => x._id.includes(key));
        let total = (month?.dates || []).reduce((acc, x) => {
            if (x) { acc += x; }
            return acc;
        }, 0)
        let isCurrent = y === (new Date()).getFullYear() && m === (new Date()).getMonth();
        return <Month key={`${y}-${m}`} p={this} register={{ key, month, y, m, total, isCurrent, }} dates={month?.dates || []}
            onClick={() => {
                if (total) {
                    this.setState({ register: { key, month, y, m, total, isCurrent, } });
                }
            }}
        />
    }
    week = (register, dates) => {
        let { y, m, w, isCurrent, style = {} } = register;
        let offset = (new Date(y, m, 1)).getDay() - 1;
        let ld = (new Date(y, m + 1, 0));
        let arr = window.util.array(7).map(i => {
            let j = ((w * 7) + i) - offset;
            if (j <= 0) j = '';
            if (j > ld.getDate()) j = '';
            return j;
        })
        let ref = undefined;
        if (isCurrent && arr.some(x => x === (new Date()).getDate()) && !style.position) {
            ref = this.currentweek;
        }
        if (arr.some(x => x !== ''))
            return <div key={w} id={w} style={{ ...{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-around', margin: '0px 0px', flexShrink: 0, borderBottom: !style.position ? '0.5px solid var(--brdr)' : 'none', background: 'var(--bg)' }, ...style }}
                ref={ref}
            >
                {
                    arr.map((j, k) => {
                        let date = new Date(y, m, j);
                        let isToday = isCurrent && j === (new Date()).getDate();
                        let isSelected = Date.parse(this.state.showCalendar) === Date.parse(date);
                        return <DateChip key={j + '-' + k} style={style}
                            isToday={isToday} isSelected={isSelected}
                            date={date}
                            day={k}
                            dated={j} p={this}
                            onClick={(e) => {
                                if (register.total) {
                                    this.setState({ register: { ...register, date: j } });
                                }
                            }}
                            count={dates[j] || 0}
                        />
                    })
                }
            </div >
        return null;
    }
    render() {
        let register = this.state.register;

        return (<div style={{ position: 'absolute', inset: 10, alignSelf: 'stretch', flex: 1, justifyContent: 'flex-start', overflow: 'hidden', background: 'var(--bg)', flexDirection: 'row', alignItems: 'flex-start', borderRadius: 10 }}>
            <div id="calendar" style={{ flex: '1 1 100%', alignSelf: 'stretch', height: '100%', overflow: 'visible' }}>
                <div id="weekdays" style={{ position: 'absolute', top: 0, width: '100%', alignSelf: 'stretch', height: 30, flexShrink: 0, flexDirection: 'row', zIndex: 1, fontSize: 11, borderBottom: '1px solid grey', justifyContent: 'space-around', background: 'var(--bg)', padding: '0px 20px 0px 10px' }}>
                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'].map(x => {
                        return <div key={x} style={{ width: 30, height: 20, flexShrink: 0, borderRadius: 20, color: 'var(--txt)', boxSizing: 'border-box' }}>{x}</div>
                    })}
                </div>
                <div id="months" ref={this.container} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, scrollSnapType: 'y mandatory', overflow: 'hidden scroll', flexShrink: 0, height: '100%', width: '100%', background: 'transparent', justifyContent: 'flex-start', padding: '0px 0px 0px 10px', zIndex: 0, fontSize: 10 }}>

                    {this.years.map(y => {
                        return this.year(y);
                    })}

                </div>
            </div>
            {/* {register ? <List register={register} p={this} /> : null} */}
        </div>);
    }
};

class DateChip extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        let { isSelected, dated, date, isToday, style, count, day } = this.props;
        return (<div style={{
            width: 30, height: 45, flexShrink: 0,
            borderRadius: style.position || isToday ? 15 : 0,
            opacity: dated === '' ? 0 : 1,
            color: isSelected ? 'white' : [0, 6].includes(day) ? 'grey' : 'var(--txt)', boxSizing: 'border-box',
            justifyContent: 'flex-end'
        }}
            onClick={this.props.onClick ? (e) => {
                this.props.onClick(e);
            } : undefined}

        >
            {count ? <div style={{
                position: 'absolute', right: 0, top: 5, fontSize: count >= 100 ? 9 : 11, fontWeight: 'bold',
                background: 'black', //isUpcoming ? 'var(--cta)' :
                width: 20, height: 20,
                borderRadius: 10,
                color: 'white'
            }}>{count}</div> : null}
            <span style={{ background: isToday ? '#f56767' : 'transparent', color: isToday ? 'var(--txti)' : 'var(--txt)', borderRadius: 20, padding: '2.5px 5px', fontWeight: isToday ? 'bold' : 'normal', fontSize: 13, border: 'none' }}>{dated}</span>
        </div>);
    }
}
class Month extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.month = React.createRef();
    }
    componentDidMount() {
        let { y, m } = this.props;
        let observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {

                if (entry.intersectionRatio > 0) {
                    // console.log(this.month.current.id, 'isFocus');
                    this.props.p.setState({ focus: { title: this.month.current.id, key: y + "-" + (m + 1).toString().padStart(2, '0') } })
                } else if (entry.intersectionRatio === 0) {
                    // console.log(this.month.current.id, 'isBlur');
                }
            });
        }, {
            root: this.props.p.container.current,
            rootMargin: '-40% -40%',
            threshold: [0]
        });
        observer.observe(this.month.current);
    }
    render() {
        let { y, m, isCurrent, total } = this.props.register;
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        // let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'];
        return (<div key={m} id={`${months[m]} ${y}`} ref={this.month} style={{ alignSelf: 'stretch', flexDirection: 'column', gap: 5, scrollSnapAlign: 'center', scrollSnapStop: 'always', margin: '0px 20px 0px 0px', flexShrink: 0, }}

        >
            <div style={{
                color: isCurrent ? 'var(--cta)' : 'var(--txt)', fontSize: 16, fontWeight: 500, marginTop: 20
            }}
                onClick={(e) => {
                    this.props.onClick && this.props.onClick();
                }}
            >
                {months[m]} {y} ({total || 0})
            </div>
            {window.util.array(6).map(w => {
                return this.props.p.week({ ...this.props.register, w }, this.props.dates);
            })}
        </div>);
    }
}
// class List extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {}
//         this.container = React.createRef();
//     }
//     records = (data) => {
//         return data.split('\n\n').filter(x => x).map(card => decode(card)).sort((a, b) => Date.parse(a.date) - Date.parse(b.date)).map(record => {
//             let scrollIntoView = false
//             if (this.props.register.date && new Date(record.date).getDate() === this.props.register.date) {
//                 scrollIntoView = true;
//             }
//             return <div key={record.id} style={{
//                 alignSelf: 'stretch', padding: 20, background: record.comment ? 'var(--bg)' : 'var(--bg)', borderRadius: 15, boxShadow: 'var(--neo)',
//                 margin: '20px 15px 20px 20px', gap: 5, fontSize: 14
//             }}
//                 ref={(e) => {
//                     if (scrollIntoView && e) {
//                         e.scrollIntoViewIfNeeded({ behavior: 'smooth' });
//                     }
//                 }}
//             >
//                 <div style={{ position: 'absolute', right: '100%', top: 0, writingMode: 'vertical-lr', transform: 'rotate(180deg)', textOrientation: 'mixed', fontSize: 10, alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', padding: '5px 0px', gap: 10, height: '100%' }}>
//                     <span style={{ fontWeight: 'bold' }}>{record.date} {record.facility}</span>
//                     <span style={{ background: 'var(--fg)', borderRadius: 10, padding: '10px 0px' }}>{record['B.type'][0]}</span>
//                 </div>
//                 <div style={{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between', fontSize: 12, borderBottom: '1px solid grey' }}>
//                     Observed:
//                     {record['D.observed'] ? <span>{record['D.observed'][0]}</span> : null}
//                 </div>
//                 <div style={{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between', fontSize: 12, borderBottom: '1px solid grey' }}>
//                     Location:
//                     {record['E.location'] ? <span>{record['E.location'][0]}</span> : null}
//                 </div>
//                 {['observation', 'conversation', 'reinforcement', 'comment', 'members'].map(key => {
//                     if (!record[key]) return null;
//                     return <React.Fragment key={key}>
//                         <span style={{
//                             alignSelf: 'flex-end',
//                             borderBottom: key === 'reinforcement' ? `1px solid ${(record['C.agreement'] || [])[0] === 'Reinforced Safe Behavior' ? 'green' : 'red'}` : '1px solid grey',
//                             fontWeight: 'bold',
//                             color: key === 'reinforcement' ? (record['C.agreement'] || [])[0] === 'Reinforced Safe Behavior' ? 'green' : 'red' : 'inherit'
//                         }}>{key}</span>
//                         <div style={{ display: 'block', alignSelf: 'stretch', flexDirection: 'row', padding: 5, justifyContent: 'flex-start', gap: 4, textAlign: 'left', fontSize: 12 }}>
//                             {record[key]}
//                         </div>
//                     </React.Fragment>

//                 })
//                 }
//                 <div style={{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'flex-end', flexWrap: 'wrap', padding: 5, fontSize: 10, gap: 10, borderTop: `1px solid grey` }}>
//                     {
//                         (record['F.behavior'] || []).map(behavior => {
//                             return <span key={behavior} style={{ alignSelf: 'flex-end', background: 'var(--fg)', borderRadius: 10, padding: '0px 10px' }}>{behavior}</span>
//                         })
//                     }
//                 </div>

//             </div >
//         })

//     }

//     render() {
//         let { register } = this.props;
//         let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
//         return (
//             <div style={{ position: 'absolute', inset: 0, zIndex: 1, background: 'var(--bg)', justifyContent: 'flex-start', }}>
//                 <div style={{
//                     color: register.isCurrent ? 'var(--cta)' : 'var(--txt)', fontSize: 16, fontWeight: 500, marginTop: 0, alignSelf: 'stretch',
//                     borderBottom: '0.5px solid grey', height: 30
//                 }}
//                 >
//                     {months[register.m]} {register.y} ({register.total || 0})
//                     <i translate="no" className="material-icons" style={{ color: 'var(--txt)', fontSize: 24, position: 'absolute', left: 10, top: 2 }}
//                         onClick={(e) => {
//                             this.props.p.setState({ register: null })
//                         }}
//                     >
//                         close
//                     </i>
//                 </div>
//                 <div style={{ alignSelf: 'stretch', flex: 1, overflow: 'hidden scroll', justifyContent: 'flex-start' }}>
//                     {this.records(register.month.data)}
//                 </div>
//             </div>
//         );
//     }
// }
export { Calendar }