import smoothscroll from 'smoothscroll-polyfill';

export default class util {
    constructor() {

        window.util = this;
        this.polyfills();

       
    }
    without = (obj, keys) => {
        return Object.fromEntries(Object.entries(obj).filter(x => !keys.includes(x[0])));
    }
    language = (lang) => {
        return new Intl.DisplayNames(['en'], { type: 'language' }).of(lang)
    }
    uuid = () => {
        let data = new Uint8Array(16);
        window.crypto.getRandomValues(data);
        data[6] = (data[6] & 0x0f) | 0x40;
        data[8] = (data[8] & 0x3f) | 0x80;
        let hex = Array.from(data, byte => byte.toString(16).padStart(2, '0')).join('');
        return `${hex.substr(0, 8)}-${hex.substr(8, 4)}-4${hex.substr(12, 3)}-${((data[15] & 0x3f) | 0x80).toString(16).padStart(2, '0')}${hex.substr(16, 2)}-${hex.substr(18, 12)}`;
    }
    parse = (data) => {
        try {
            return JSON.parse(data)
        } catch (error) {
            return data;
        }
    }
    stringify = (data) => {
        try {
            return JSON.stringify(data)
        } catch (error) {
            return data;
        }
    }
    query = () => {
        return new URLSearchParams(window.location.search)
    }
    trial = (f) => {
        try {
            return f()
        } catch (error) {
            return null
        }
    }
    polyfills = () => {
        if (this.isSafariIOS())
            smoothscroll.polyfill();
    }
    isMobile = () => {
        return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|LinkedInApp/i.test(navigator.userAgent) || window.innerWidth <= 500
    }
    isTab = () => {
        return (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) || navigator.platform === 'iPad' || /iPad/i.test(navigator.userAgent)
    }
    isSafari = () => {
        return window.safari ? true : false
    }
    isSafariIOS = () => {
        return navigator.standalone !== undefined
        //return /^(?=.*(iPhone|iPad|iPod))(?=.*AppleWebKit)(?!.*(criOS|fxiOS|opiOS|chrome|android)).*/i.test(navigator.userAgent)
    }
    isTouchDevice = () => {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));
    }
    hasSharedWorker = () => {
        return false;
        if (window.ReactNativeWebView) {
            return false;
        }
        return window.SharedWorker ? true : false
    }
    source = () => {
        if (window.location.search.includes('et=')) {
            return 'webapp'
        }
        if (window.location.search.includes('_host_Info=')) {
            return 'app'
        }
        if (window.location.search.includes('ac=')) {
            return 'ac'
        }
        return 'web';
    }
    scrollTo = (el, obj) => {
        return new Promise(resolve => {
            el.scrollTo(obj);
            let prop, done = false;
            if (obj.top) {
                prop = ['scrollTop', obj.top];
            } else {
                prop = ['scrollLeft', obj.left];
            }
            let f = (e) => {
                if (el[prop[0]] >= prop[1] && !done) {
                    done = true;
                    el.removeEventListener('scroll', f)
                    resolve(true);
                }
            };
            el.addEventListener('scroll', f)
        })

    }
    onScrollBelow = (el, obj) => {
        return new Promise(resolve => {
            let prop, done = false;
            if (obj.top) {
                prop = ['scrollTop', obj.top];
            } else {
                prop = ['scrollLeft', obj.left];
            }
            let f = (e) => {
                if (el[prop[0]] <= prop[1] && !done) {
                    el.onscroll = null;
                    el.removeEventListener('scroll', f)
                    done = true;
                    resolve(true)
                }
            };
            el.addEventListener('scroll', f)
        })
    }
    scrollIntoView = (el, container) => {
        let gbc = el.getBoundingClientRect();
        let cgbc = container.getBoundingClientRect();
        container.scrollTo({
            top: gbc.top + container.scrollTop - cgbc.top - cgbc.height / 2,
        });
        return el.getBoundingClientRect();
    }
    // createRef = () => {
    //     let resolver
    //     let ref = {
    //         current: null,
    //         promise: new Promise(resolve => {
    //             resolver = resolve;
    //         }),
    //         ref: (e) => {
    //             if (e) {
    //                 resolver(e);
    //                 ref.current = e;
    //             }
    //         },
    //         reset: () => {
    //             ref.promise = new Promise(resolve => {
    //                 resolver = resolve;
    //             });
    //             ref.current = null;
    //         }
    //     }
    //     return ref;
    // }
    createRef = () => {
        let _resolve
        let _ref = { current: null, promise: new Promise(resolve => { _resolve = resolve }) }
        return new Proxy(_ref, {
            get: (target, key) => {
                return target[key]
            },
            set: (target, key, value) => {
                target[key] = value;
                if (value) {
                    _resolve(value);
                } else {
                    target.promise = new Promise(resolve => { _resolve = resolve });
                }
                return true;
            }
        })
    }
    equal = (a, b) => {

        if (a === b) return true;

        if (a && b && typeof a == 'object' && typeof b == 'object') {
            if (a.constructor !== b.constructor) return false;

            var length, i, keys;
            if (Array.isArray(a)) {
                length = a.length;
                if (length != b.length) return false;
                for (i = length; i-- !== 0;)
                    if (!this.equal(a[i], b[i])) return false;
                return true;
            }


            if ((a instanceof Map) && (b instanceof Map)) {
                if (a.size !== b.size) return false;
                for (i of a.entries())
                    if (!b.has(i[0])) return false;
                for (i of a.entries())
                    if (!this.equal(i[1], b.get(i[0]))) return false;
                return true;
            }

            if ((a instanceof Set) && (b instanceof Set)) {
                if (a.size !== b.size) return false;
                for (i of a.entries())
                    if (!b.has(i[0])) return false;
                return true;
            }

            if (ArrayBuffer.isView(a) && ArrayBuffer.isView(b)) {
                length = a.length;
                if (length != b.length) return false;
                for (i = length; i-- !== 0;)
                    if (a[i] !== b[i]) return false;
                return true;
            }


            if (a.constructor === RegExp) return a.source === b.source && a.flags === b.flags;
            if (a.valueOf !== Object.prototype.valueOf) return a.valueOf() === b.valueOf();
            if (a.toString !== Object.prototype.toString) return a.toString() === b.toString();

            keys = Object.keys(a);
            length = keys.length;
            if (length !== Object.keys(b).length) return false;

            for (i = length; i-- !== 0;)
                if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false;

            for (i = length; i-- !== 0;) {
                var key = keys[i];

                if (!this.equal(a[key], b[key])) return false;
            }

            return true;
        }

        // true if both NaN, false otherwise
        return a !== a && b !== b;
    }
    array = (n, start = 0) => {
        return Array.from(Array(n)).map((x, i) => i + start)
    }
    deepproxy = (obj, onChange) => {
        let handler = (path = []) => ({
            get: (target, key) => {
                if (key === 'isProxy')
                    return true;
                if (typeof target[key] === 'object' && target[key] != null)
                    return new Proxy(
                        target[key],
                        handler([...path, key])
                    );
                return target[key];

            },
            set: (target, key, value) => {
                if (!this.equal(target[key], value)) {
                    target[key] = value;
                    if (onChange) onChange(key, value);
                }
                return true;
            }
        });
        return new Proxy(obj, handler());
    }
    pseudo = (html) => {
        let pure = html // DOMPurify.sanitize(html)
        return new DOMParser().parseFromString(pure, 'text/html');
    }
    clone = (obj) => {
        return JSON.parse(JSON.stringify(obj))
    }
    rndmUID = () => {

        return Date.now() + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) //+ Math.random().toString(36).substring(2, 15);
        // this should be strong enough with neglible posibility of duplication of uid; should we also use hash for the object?
    }
    current = () => {
        let current = new Date();
        return (current.getHours() * 60) + current.getMinutes();
    }
    today = () => {
        let d = new Date();
        return new Date(d.getFullYear(), d.getMonth(), d.getDate());
    }
    day = (d) => {
        let locale = (d || window.app.state.date).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        })
        let ds = `${locale.split('/')[2]}${locale.split('/')[1]}${locale.split('/')[0]}`;
        return ds
    }
    date = (day) => {
        return `${day.substring(0, 4)}-${day.substring(4, 6)}-${day.substring(6, 8)}`
    }
    time = (min) => {
        return (Math.floor(min / 60).toString().length === 1 ? '0' : '') + Math.floor(min / 60) + ":" + (Math.floor(min % 60).toString().length === 1 ? '0' : '') + Math.floor(min % 60);
    }
    min = (time) => {
        let t = time.split(':')
        return Number(t[0]) * 60 + Number(t[1]);
    }
    localdt = (date, time) => {
        let locale = new Date(date || Date.now()).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        })
        return `${locale.split('/')[2]}-${locale.split('/')[1]}-${locale.split('/')[0]}${time ? 'T' + time : ''}`;
    }
    tolocaldate = (date, add) => {
        if (!date) date = new Date();
        if (add) {
            date.setDate(date.getDate() + add);
        }
        let locale = new Date(date).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        })
        return `${locale.split('/')[2]}-${locale.split('/')[1]}-${locale.split('/')[0]}`;
    }
    tolocaltime = (date) => {
        if (!date) date = new Date();
        let time = new Date(date).toLocaleTimeString('en-GB').substring(0, 5);
        return time;
    }
    tolocaldt = (date, add) => {
        if (!date) date = new Date();
        if (add) {
            date.setDate(date.getDate() + add);
        }
        let ld = this.tolocaldate(date);
        let time = this.tolocaltime(date);
        return `${ld}${time ? 'T' + time : ''}`;
    }
    tolocaledt = (date, add) => {
        if (!date) date = new Date();
        if (add) {
            date.setDate(date.getDate() + add);
        }
        let ld = this.tolocaldate(date);
        let time = this.tolocaltime(date);
        return `${ld}${time ? ' ' + time : ''}`;
    }
    toSEdt = (dt, type) => {
        switch (type) {
            case 'start':
                return new Date(new Date(dt).setHours(0, 0, 0, 0)).toISOString();
            case 'end':
                return new Date(new Date(dt).setHours(23, 59, 59, 999)).toISOString();
            default:
                break;
        }
    }
    toISOdt = (date, add, type) => {
        let d = new Date(date);
        if (add) {
            d.setDate(d.getDate() + add);
        }
        if (type) {
            return this.toSEdt(d.toISOString(), type)
        }
        return d.toISOString()
    }
    daterange = (dt, n) => {
        let d = new Date(dt);
        d.setDate(d.getDate() + n);
        return [dt, d]
    }
    datearray = (dt, n) => {
        return this.array(n).map(x => {
            let d = new Date(dt);
            if (x)
                d.setDate(d.getDate() + x);
            return d;
        })
    }
    flows = (n, f) => {
        let flows = [...(n.nodal.flows || [])];
        if (flows[0] && flows[0].f === f) {

        } else {
            flows.unshift({ f, dt: Date.now() })
        }
        return flows;
    }
    trasheddate = (n) => {
        if ((n.nodal?.flow || [])[0] === 'trash') {
            let dt = (n.nodal?.flows || [{ f: 'trash', dt: n.modified }])[0].dt;
            return this.tolocaldate(dt);
        }
        return null;
    }
    occurs = (wh, recurrence) => {
        let tolocaldate = (date) => {
            if (!date) date = new Date();
            let locale = new Date(date).toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
            })
            return `${locale.split('/')[2]}-${locale.split('/')[1]}-${locale.split('/')[0]}`;
        }
        let when = tolocaldate(wh);
        let occurence = [when];
        let repeat = (fn) => {
            for (let i = 1; i <= Number(recurrence.until); i++) {
                fn(i);
            }
        }
        let add_days = (d) => {
            let date = Date.parse(when);
            return tolocaldate(new Date(date + (d * 24 * 3600 * 1000)))
        }
        let add_week = (d) => {
            if ((recurrence.on || []).length) {
                let wd = new Date(when);
                wd.setDate(wd.getDate() - wd.getDay());
                return recurrence.on.map(x => {
                    let index = ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'].findIndex(w => w === x);
                    return tolocaldate(new Date(Date.parse(wd) + (7 * d * 24 * 3600 * 1000) + (index * 24 * 3600 * 1000)));
                })
            } else {
                let date = Date.parse(when);
                return [tolocaldate(new Date(date + (7 * d * 24 * 3600 * 1000)))]
            }

        }
        let add_year = (d) => {
            let wd = new Date(when);
            let dt = wd.getDate();
            let dm = wd.getMonth();
            let dy = wd.getFullYear();
            let base = new Date(dy, 0, dt);
            if ((recurrence.on || []).length) {
                base.setFullYear(dy + d);
                return recurrence.on.map(x => {
                    let td = new Date(base);
                    let index = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].findIndex(w => w === x);
                    td.setMonth(index);
                    if (td.getMonth() === index) {
                        return tolocaldate(td);
                    }
                    return null
                }).filter(x => x);
            } else {
                base.setFullYear(dy + d);
                base.setMonth(dm);
                if (base.getMonth() === dm) {
                    return [tolocaldate(base)];
                }
                return [];
            }

        }
        let add_month = (d) => {
            if ((recurrence.on || []).length) {
                let wd = new Date(when);
                wd.setDate(1);
                let nm = wd.getMonth() + d
                wd.setMonth(nm);
                let dates = recurrence.on.map(x => {
                    return tolocaldate(new Date(Date.parse(wd) + ((x - 1) * 24 * 3600 * 1000)));
                })
                return dates.filter(dt => new Date(dt).getMonth() === nm % 12)
            } else {
                let wd = new Date(when);
                let nm = wd.getMonth() + d;
                wd.setMonth(nm);
                if (wd.getMonth() === nm % 12) {
                    return [tolocaldate(wd)]
                } else {
                    return []
                }
            }
        }
        switch (recurrence?.repeat) {
            case 'daily':
                repeat((i) => {
                    let d = add_days(i * recurrence.every)
                    occurence.push(d);
                })
                break;
            case 'weekly':
                repeat((i) => {
                    occurence.push(...add_week(i * recurrence.every));
                })
                break;
            case 'monthly':
                repeat((i) => {
                    occurence.push(...add_month(i * recurrence.every));
                })
                break;
            case 'yearly':
                repeat((i) => {
                    let ay = add_year(i * recurrence.every);
                    //console.log(ay);
                    occurence.push(...ay);
                })
                break;
            default:
                break;
        }
        return occurence.sort((a, b) => new Date(a) - new Date(b));
    }
    occurence = (n) => {
        let when = n.nodal?.when;
        let recurrence = n.nodal?.recurrence;
        let occurs = this.occurs(when, recurrence);
        return occurs;
    }
    next = (n) => {
        let today = this.tolocaldate();
        return this.occurence(n).filter(x => new Date(x) >= new Date(today))[0];
    }
    due = (n) => {
        if (!n.nodal?.when) return null;
        let occurs = this.occurence(n);
        // let today = this.tolocaldate();
        let time = 'T' + this.tolocaltime(n.nodal?.when);
        let found = occurs.find(o => new Date(o + time) >= new Date()) || occurs.last();
        return found + time;
    }
    nextslot = (min) => {
        let time = this.tolocaltime();
        let start = (Math.floor(this.min(time) / 15) * 15) + min;
        return this.toISOdt(this.tolocaldate() + 'T' + window.util.time(start))
    }
    dateadd = (d, n) => {
        let date = new Date(d);
        date.setDate(date.getDate() + n);
        return this.tolocaldate(date);
    }
    futuredt = (d) => {
        return new Date(d) >= new Date()
    }
    gettimeslots = (when, effort) => {
        let d = new Date(when);
        let date = this.tolocaldate(d);
        let time = this.tolocaltime(d);
        let start = this.min(time);
        let end = start + effort;
        return {
            date,
            time,
            effort,
            start,
            end,
            slots: [Math.floor(start / 15), Math.floor((end - 0.5) / 15)]
        }
    }
    delta = (t2, t1) => {
        return this.min(t2) - this.min(t1);
    }
    proxy = (obj, onChange) => {
        let handler = (path = []) => ({
            get: (target, key) => {
                if (key === 'assign') {
                    return (assign) => {
                        let oldvalue = { ...target };
                        Object.assign(target, assign);
                        target.modified = Date.now();
                        onChange(key, assign, oldvalue);
                    }
                }
                if (key === '_value') {
                    return target;
                }
                return target[key];
            },
            set: (target, key, value) => {
                if (!this.equal(target[key], value)) {
                    let oldvalue = target[key];
                    target[key] = value;
                    target.modified = Date.now();
                    if (onChange) onChange(key, value, oldvalue);
                }
                return true;
            }
        });
        return new Proxy(obj, handler());
    }
    script = (id, src) => {
        return new Promise((resolve, reject) => {
            const element = document.getElementById(id);
            if (element) {
                return resolve(true);
            }
            const script = document.createElement('script');
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('id', id);
            script.setAttribute('src', src);
            script.addEventListener('load', resolve);
            script.addEventListener('error', () => reject('error'));
            script.addEventListener('abort', () => reject('abort'));
            document.getElementsByTagName('head')[0].appendChild(script);
        })

    }
    path = (el) => {
        let path = [];
        let e = el;
        while (e && e.tagName !== 'BODY' && e.id !== 'app') {
            if (e.id)
                path.push(e.id);
            e = e.parentNode;
        }
        path.reverse();
        return path;
    }
    clicked = (el) => {
        let e = el, clicked;
        while (e && e.id !== 'app') {
            if (e && typeof e.onclick === 'function' && (e.dataset.anime || !e.dataset.noanime)) {
                clicked = e;
                e = null;
            } else {
                e = e.parentNode;
            }
        }
        return clicked;
    }
    bluranimate = (el) => {
        el.style.animation = 'blur 0.5s linear';
        el.onanimationend = () => {
            el.onanimationend = null;
            el.style.animation = 'none';
        };
    }
    isIAB = () => {
        if (window.alphactrl) {
            return false;
        }
        return /FB_IAB|FB[\w_]+|LinkedInApp|Instagram|Twitter|MicroMessenger|FBAN|FBIOS/i.test(navigator.userAgent)
    }
    subscription = (value, update) => {
        let x = {
            value: value,
            subscriptions: {}
        };
        return new Proxy(x, {
            get: (target, key) => {
                if (key === 'subscribe') {
                    return (f) => {
                        let id = Math.random().toString(36).substring(2, 15)
                        target.subscriptions[id] = f;
                        if (target.value)
                            f(target.value);
                        return {
                            subscribed: Date.now(),
                            id: id,
                            unsubscribe: () => {
                                delete target.subscriptions[id];
                            }
                        }
                    }
                }
                if (key === 'update') {
                    if (!update) {
                        return () => { };
                    } else
                        return (value) => {
                            if (!this.equal(value, target.value)) {
                                update(value);
                                target.value = value;
                                Object.values(target.subscriptions).forEach(f => {
                                    f(value);
                                });
                            }
                        }
                }
                return target[key]
            },
            set: (target, key, value) => {
                if (key === 'value') {
                    if (!this.equal(value, target[key])) {
                        target[key] = value;
                        Object.values(target.subscriptions).forEach(f => {
                            f(value);
                        });
                    }
                }
                return true;
            }
        })
    }
    capabilities = (key) => {
        switch (key) {
            case 'EventWorker':
                return ['macos', 'ios'].includes(window.alphactrl?.OS) && (window.alphactrl?.capabilities || []).includes('EventWorker')
            default:
                return false;
        }
    }
    haptic = () => {
        if (window.app.subscription)
            window.app.subscription.request({ type: "AlphaUtil.haptic" })
    }
    cosineSimilarity = (a, b) => {
        if (!a || !b) return 0;
        var dotProduct = 0;
        var normA = 0;
        var normB = 0;
        for (var i = 0; i < a.length; i++) {
            dotProduct += a[i] * b[i];
            normA += a[i] * a[i];
            normB += b[i] * b[i];
        }
        return dotProduct / (Math.sqrt(normA) * Math.sqrt(normB));
    }
    decode_jwt = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return this.parse(jsonPayload);
    }
    findIndexUptolimit = (arr, limit, valueKey) => {
        let sum = 0;
        for (let i = 0; i < arr.length; i++) {
            sum += arr[i][valueKey];
            if (sum > limit) {
                return i;
            }
        }
        return arr.length; // if no index is found
    }
    tableToJson = (html) => {
        // Define regex patterns
        const tablePattern = /<table.*?>([\s\S]*?)<\/table>/gm;
        const rowPattern = /<tr.*?>([\s\S]*?)<\/tr>/gm;
        const cellPattern = /<t(?:h|d).*?>([\s\S]*?)<\/t(?:h|d)>/gm;

        // Match table elements
        const tableMatches = html.matchAll(tablePattern);
        const tableData = [];

        // Loop through table elements
        for (const tableMatch of tableMatches) {
            const tableHtml = tableMatch[0];
            const rows = [];

            // Match row elements
            const rowMatches = tableHtml.matchAll(rowPattern);

            // Loop through row elements
            for (const rowMatch of rowMatches) {
                const rowHtml = rowMatch[0];
                const cells = [];
                let columnIndex = 0;

                // Match cell elements
                const cellMatches = rowHtml.matchAll(cellPattern);

                // Loop through cell elements
                for (const cellMatch of cellMatches) {
                    const cellHtml = cellMatch[0];
                    const cellContent = this.removeHtmlTags(cellMatch[1].trim());

                    // Handle colspan and rowspan attributes
                    const colspan = parseInt((cellHtml.match(/colspan="(.*?)"/) || [])[1] || 1);
                    const rowspan = parseInt((cellHtml.match(/rowspan="(.*?)"/) || [])[1] || 1);

                    // Add cell content to row data
                    for (let i = 0; i < colspan; i++) {
                        for (let j = 0; j < rowspan; j++) {
                            const currentColumnIndex = columnIndex + i;
                            cells[currentColumnIndex] = cellContent;
                        }
                    }

                    columnIndex += colspan;
                }

                rows.push(cells);
            }

            // Convert row data to JSON format
            const headers = rows.shift();
            const rowData = [];
            for (const row of rows) {
                const obj = {};
                for (let i = 0; i < row.length; i++) {
                    obj[headers[i]] = row[i];
                }
                rowData.push(obj);
            }

            tableData.push(rowData);
        }

        return tableData;
    }
    table2text = (html) => {

        const newString = html.replace(/(<table.*?>[\s\S]*?<\/table>)/gm, (match) => {
            return this._table2text(match)
        });
        return newString;
    }
    _table2text = (tableHtml) => {
        // Define regex patterns
        const rowPattern = /<tr.*?>([\s\S]*?)<\/tr>/gm;
        const cellPattern = /<t(?:h|d).*?>([\s\S]*?)<\/t(?:h|d)>/gm;

        // Match table elements
        const tableData = [];
        const rows = [];

        // Match row elements
        const rowMatches = tableHtml.matchAll(rowPattern);

        // Loop through row elements
        for (const rowMatch of rowMatches) {
            const rowHtml = rowMatch[0];
            const cells = [];
            let columnIndex = 0;

            // Match cell elements
            const cellMatches = rowHtml.matchAll(cellPattern);

            // Loop through cell elements
            for (const cellMatch of cellMatches) {
                const cellHtml = cellMatch[0];
                const cellContent = this.removeHtmlTags(cellMatch[1].trim());

                // Handle colspan and rowspan attributes
                const colspan = parseInt((cellHtml.match(/colspan="(.*?)"/) || [])[1] || 1);
                const rowspan = parseInt((cellHtml.match(/rowspan="(.*?)"/) || [])[1] || 1);

                // Add cell content to row data
                for (let i = 0; i < colspan; i++) {
                    for (let j = 0; j < rowspan; j++) {
                        const currentColumnIndex = columnIndex + i;
                        cells[currentColumnIndex] = cellContent;
                    }
                }

                columnIndex += colspan;
            }

            rows.push(cells);
        }

        // Convert row data to JSON format
        const headers = rows.shift();
        const rowData = [];
        for (const row of rows) {
            const obj = {};
            for (let i = 0; i < row.length; i++) {
                obj[headers[i]] = row[i];
            }
            rowData.push(obj);
        }

        let text = rowData.map(row => Object.entries(row).map(x => x[0] + ' : ' + x[1]).join('\n')).join('\n\n')

        return text;
    }
    removeHtmlTags = (text) => {
        const regex = /(<([^>]+)>)/ig;
        return text.replace(regex, '').replace(/↵/g, ' ').replace(/\n/g, ' ').trim();
    }
    wordCount = (text) => {
        const regex = /[^\s\.,!?]+/g; // regular expression to match words
        const matches = text.replace(/\n/g, '').match(regex);
        return matches ? matches.length : 0;
    }
    estimate = (srs) => {
        let words = srs.map(x => this.wordCount(x.txt)).reduce((a, b) => a + b, 0);
        let pages = srs.length;
        //time required to find each page: 10sec;
        // Time required to read words: 3 words/sec;
        return (words * 3) + (pages * 10)
    }
    inrange = (key, range) => {
        return (d) => new Date(d[key]) >= new Date(range[0]) && new Date(d[key]) <= new Date(range[1])
    }
    objpath = (obj, path) => {
        let res = obj;
        try {
            path.split('/').forEach(key => {
                if (/^\[.*\]/.test(key)) {
                    let k = key.match(/^\[(.*)\]/)[1];
                    if (Number.isInteger(Number(k))) {
                        res = res[Number(k)]
                    } else if (k === 'last') {
                        res = res[res.length - 1]
                    }
                } else {
                    res = res[key];
                }

            })
            return res;
        } catch (error) {
            return undefined
        }
    }
    findNested = (obj, key, memo) => {
        var i,
            proto = Object.prototype,
            ts = proto.toString,
            hasOwn = proto.hasOwnProperty.bind(obj);

        if ('[object Array]' !== ts.call(memo)) memo = [];

        for (i in obj) {
            if (hasOwn(i)) {
                if (key(obj)) {
                    memo.push(obj);
                } else if ('[object Array]' === ts.call(obj[i]) || '[object Object]' === ts.call(obj[i])) {
                    this.findNested(obj[i], key, memo);
                }
            }
        }
        return memo;
    }
    print = (outerHTML) => {
        var printWindow = window.open('', '_blank', 'height = 600, width = 800'); printWindow.document.write(outerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    }
    groupBy = (array, f) => {
        let groups = {};
        let key = f[0];
        array.forEach(it => {
            if (it[key])
                (Array.isArray(it[key]) ? it[key] : [it[key]]).forEach(k1 => {
                    groups[k1] = [...(groups[k1] || []), it];
                })
        })

        if (f.length > 1) {
            Object.keys(groups).forEach(k => {
                groups[k] = this.groupBy([...groups[k]], f.slice(1));
            })
        }
        if (Object.keys(groups).length) {

            return groups
        }
        return array;
    }
    sentences = (text, user_options) => {
        const newline_placeholder = " @~@ ";
        const newline_placeholder_t = newline_placeholder.trim();
        const addNewLineBoundaries = new RegExp("\\n+|[-#=_+*]{4,}", "g");
        const splitIntoWords = new RegExp("\\S+|\\n", "g");
        let sanitizeHtml = (text, opts) => {
            // Strip HTML from Text using browser HTML parser
            if ((typeof text == 'string' || text instanceof String) && typeof document !== "undefined") {
                var $div = document.createElement("DIV");
                $div.innerHTML = text;
                text = ($div.textContent || '').trim();
            }
            //DOM Object
            else if (typeof text === 'object' && text.textContent) {
                text = (text.textContent || '').trim();
            }

            return text;
        }
        let stringHelper = {
            endsWithChar: (word, c) => {
                if (c.length > 1) {
                    return c.indexOf(word.slice(-1)) > -1;
                }

                return word.slice(-1) === c;
            },
            endsWith: (word, end) => {
                return word.slice(word.length - end.length) === end;
            }
        }
        let Match = new Tokenizer();
        if (!text || typeof text !== "string" || !text.length) {
            return [];
        }

        if (!/\S/.test(text)) {
            return [];
        }

        const options = {
            newline_boundaries: false,
            html_boundaries: false,
            html_boundaries_tags: ["p", "div", "ul", "ol"],
            sanitize: false,
            allowed_tags: false,
            preserve_whitespace: false,
            abbreviations: null,
        };

        if (typeof user_options === "boolean") {
            options.newline_boundaries = true;
        } else {
            for (const k in user_options) {
                options[k] = user_options[k];
            }
        }

        Match.setAbbreviations(options.abbreviations);

        if (options.newline_boundaries) {
            text = text.replace(addNewLineBoundaries, newline_placeholder);
        }

        if (options.html_boundaries) {
            const html_boundaries_regexp =
                `(<br\\s*\\/?>|<\\/(${options.html_boundaries_tags.join("|")})>)`;
            const re = new RegExp(html_boundaries_regexp, "g");
            text = text.replace(re, "$1" + newline_placeholder);
        }

        if (options.sanitize || options.allowed_tags) {
            if (!options.allowed_tags) {
                options.allowed_tags = [""];
            }
            text = sanitizeHtml(text, { allowedTags: options.allowed_tags });
        }

        const words = options.preserve_whitespace
            ? text.split(/(<br\s*\/?>|\S+|\n+)/).filter((token, ii) => ii % 2)
            : text.trim().match(splitIntoWords);

        let wordCount = 0;
        let index = 0;
        let temp = [];
        const sentences = [];
        let current = [];

        if (!words || !words.length) {
            return [];
        }

        for (let i = 0, L = words.length; i < L; i++) {
            wordCount++;
            current.push(words[i]);

            if (~words[i].indexOf(",")) {
                wordCount = 0;
            }

            if (
                Match.isBoundaryChar(words[i]) ||
                stringHelper.endsWithChar(words[i], "?!") ||
                words[i] === newline_placeholder_t
            ) {
                if ((options.newline_boundaries || options.html_boundaries) && words[i] === newline_placeholder_t) {
                    current.pop();
                }
                sentences.push(current);
                wordCount = 0;
                current = [];
                continue;
            }

            if (stringHelper.endsWithChar(words[i], '"') || stringHelper.endsWithChar(words[i], "”")) {
                words[i] = words[i].slice(0, -1);
            }

            if (stringHelper.endsWithChar(words[i], ".")) {
                if (i + 1 < L) {
                    if (words[i].length === 2 && isNaN(words[i].charAt(0))) {
                        continue;
                    }
                    if (Match.isCommonAbbreviation(words[i])) {
                        continue;
                    }
                    if (Match.isSentenceStarter(words[i + 1])) {
                        if (Match.isTimeAbbreviation(words[i], words[i + 1])) {
                            continue;
                        }
                        if (Match.isNameAbbreviation(wordCount, words.slice(i, 6))) {
                            continue;
                        }
                        if (Match.isNumber(words[i + 1])) {
                            if (Match.isCustomAbbreviation(words[i])) {
                                continue;
                            }
                        }
                    } else {
                        if (stringHelper.endsWith(words[i], "..")) {
                            continue;
                        }
                        if (Match.isDottedAbbreviation(words[i])) {
                            continue;
                        }
                        if (Match.isNameAbbreviation(wordCount, words.slice(i, 5))) {
                            continue;
                        }
                    }
                }
                sentences.push(current);
                current = [];
                wordCount = 0;
                continue;
            }

            if ((index = words[i].indexOf(".")) > -1) {
                if (Match.isNumber(words[i], index)) {
                    continue;
                }
                if (Match.isDottedAbbreviation(words[i])) {
                    continue;
                }
                if (Match.isURL(words[i]) || Match.isPhoneNr(words[i])) {
                    continue;
                }
            }

            if ((temp = Match.isConcatenated(words[i]))) {
                current.pop();
                current.push(temp[0]);
                sentences.push(current);
                current = [];
                wordCount = 0;
                current.push(temp[1]);
            }
        }

        if (current.length) {
            sentences.push(current);
        }

        const result = sentences.slice(1).reduce((out, sentence) => {
            const lastSentence = out[out.length - 1];

            if (lastSentence.length === 1 && /^.{1,2}[.]$/.test(lastSentence[0])) {
                if (!/[.]/.test(sentence[0])) {
                    out.pop();
                    out.push(lastSentence.concat(sentence));
                    return out;
                }
            }

            out.push(sentence);
            return out;
        }, [sentences[0]]);

        return result.map((sentence, ii) => {
            if (options.preserve_whitespace && !options.newline_boundaries && !options.html_boundaries) {
                const tokenCount = sentence.length * 2;
                if (ii === 0) {
                    tokenCount += 1;
                }
                return Match.tokens.splice(0, tokenCount).join("");
            }
            return sentence.join(" ");
        });
    }
    flat = (obj) => {
        if (Array.isArray(obj)) {
            return obj;
        }
        return Object.values(obj).map(x => this.flat(x)).flat();
    }
}

class Tokenizer {
    constructor() {
        this.abbreviations = [];
        this.englishAbbreviations = [
            "al",
            "adj",
            "assn",
            "Ave",
            "BSc", "MSc",
            "Cell",
            "Ch",
            "Co",
            "cc",
            "Corp",
            "Dem",
            "Dept",
            "ed",
            "eg",
            "Eq",
            "Eqs",
            "est",
            "est",
            "etc",
            "Ex",
            "ext", // + number?
            "Fig",
            "fig",
            "Figs",
            "figs",
            "i.e",
            "ie",
            "Inc",
            "inc",
            "Jan", "Feb", "Mar", "Apr", "Jun", "Jul", "Aug", "Sep", "Sept", "Oct", "Nov", "Dec",
            "jr",
            "mi",
            "Miss", "Mrs", "Mr", "Ms",
            "Mol",
            "mt",
            "mts",
            "no",
            "Nos",
            "PhD", "MD", "BA", "MA", "MM",
            "pl",
            "pop",
            "pp",
            "Prof", "Dr",
            "pt",
            "Ref",
            "Refs",
            "Rep",
            "repr",
            "rev",
            "Sec",
            "Secs",
            "Sgt", "Col", "Gen", "Rep", "Sen", 'Gov', "Lt", "Maj", "Capt", "St",
            "Sr", "sr", "Jr", "jr", "Rev",
            "Sun", "Mon", "Tu", "Tue", "Tues", "Wed", "Th", "Thu", "Thur", "Thurs", "Fri", "Sat",
            "trans",
            "Univ",
            "Viz",
            "Vol",
            "vs",
            "v",
        ];
        this.tokens = [];
    }

    setAbbreviations(abbr) {
        if (abbr) {
            this.abbreviations = abbr;
        } else {
            this.abbreviations = this.englishAbbreviations;
        }
    }

    isCapitalized(str) {
        return /^[A-Z][a-z].*/.test(str) || this.isNumber(str);
    }

    isSentenceStarter(str) {
        return this.isCapitalized(str) || /``|"|'/.test(str.substring(0, 2));
    }

    isCommonAbbreviation(str) {
        const noSymbols = str.replace(/[-'`~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]/gi, "");
        return this.abbreviations.includes(noSymbols);
    }

    isTimeAbbreviation(word, next) {
        if (word === "a.m." || word === "p.m.") {
            const tmp = next.replace(/\W+/g, '').slice(-3).toLowerCase();
            if (tmp === "day") {
                return true;
            }
        }
        return false;
    }

    isDottedAbbreviation(word) {
        const matches = word.replace(/[\(\)\[\]\{\}]/g, '').match(/(.\.)*/);
        return matches && matches[0].length > 0;
    }

    isCustomAbbreviation(str) {
        if (str.length <= 3) {
            return true;
        }
        return this.isCapitalized(str);
    }

    isNameAbbreviation(wordCount, words) {
        if (words.length > 0) {
            if (wordCount < 5 && words[0].length < 6 && this.isCapitalized(words[0])) {
                return true;
            }
            const capitalized = words.filter(str => /[A-Z]/.test(str.charAt(0)));
            return capitalized.length >= 3;
        }
        return false;
    }

    isNumber(str, dotPos) {
        if (dotPos) {
            str = str.slice(dotPos - 1, dotPos + 2);
        }
        return !isNaN(str);
    }

    isPhoneNr(str) {
        return str.match(/^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/);
    }

    isURL(str) {
        return str.match(/[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/);
    }

    isConcatenated(word) {
        let i = 0;
        if ((i = word.indexOf(".")) > -1 || (i = word.indexOf("!")) > -1 || (i = word.indexOf("?")) > -1) {
            const c = word.charAt(i + 1);
            if (c.match(/[a-zA-Z].*/)) {
                return [word.slice(0, i), word.slice(i + 1)];
            }
        }
        return false;
    }

    isBoundaryChar(word) {
        return word === "." || word === "!" || word === "?";
    }
}