

function RecordVoiceOverIcon(props) { return <i className="material-icons" style={props.style}>record_voice_over</i> }
function DirectionsRunIcon(props) { return <i className="material-icons" style={props.style}>directions_run</i> }
function VolumeUpIcon(props) { return <i className="material-icons" style={props.style}>volume_up</i> }
function StopIcon(props) { return <i className="material-icons" style={props.style}>stop</i> }
function PauseIcon(props) { return <i className="material-icons" style={props.style}>pause</i> }
function PlayIcon(props) { return <i className="material-icons" style={props.style}>play_circle_filled</i> }
function FaceIcon(props) { return <i className="material-icons" style={props.style}>face</i> }

export {
    RecordVoiceOverIcon,
    DirectionsRunIcon,
    VolumeUpIcon,
    StopIcon,
    PauseIcon,
    PlayIcon,
    FaceIcon,
}