export default class Core {
    constructor() {
        this.workerports = {}
        this.subscriptions = {};
        this.requests = {};
        window.app.core = this;
        this.worker = window.util.hasSharedWorker() ? new SharedWorker(new URL("./Core.js", import.meta.url), { name: 'Core' }) : new Worker(new URL("./Core.js", import.meta.url), { name: 'Core' })
        this.initWorker();
    }
    initWorker = () => {
        if (window.util.hasSharedWorker()) {
            this.worker.port.start();
        } else {
            this.worker.port = this.worker;

        }
        this.worker.port.postMessage({ action: 'init', });
        this.worker.port.onmessage = (event) => {
            let data = event.data;
            if (!data) return;
            // console.log(data);
            switch (data.action) {
                case 'init':
                    // console.log('got init', worker.displayName, data)
                    break;
                case 'subscribe':
                    if (this.subscriptions[data.id]) {
                        this.subscriptions[data.id].callback(data.result)
                    }
                    break;
                case 'response':
                    if (this.requests[data.id]) {
                        this.requests[data.id].resolve(data.result)
                    }
                    break;
                default:
                    break;
            }

        };
        this.worker.subscribe = (request, callback) => {
            return this.subscribe(this.worker.port, request, callback)
        }
        this.worker.request = (request, file) => {
            return this.request(this.worker.port, request, file)
        }
    }
    request = (port, request, file) => {
        if (port) {
            let r = {
                ...{
                    id: Math.random().toString(36).substring(2, 15),
                    action: 'request'
                }, ...request
            }
            let promise = new Promise((resolve, reject) => {
                this.requests[r.id] = { request: r, resolve: resolve, reject: reject };
                port.postMessage(r, file)
            })
            return promise;
        }
        return new Promise(resolve => resolve(false));
    }
    subscribe = (port, request, callback, type) => {
        if (port) {
            let r = {
                ...{
                    id: Math.random().toString(36).substring(2, 15),
                    action: 'subscribe'
                }, ...request
            }
            let promise = new Promise((resolve, reject) => {
                this.subscriptions[r.id] = { request: r, resolve: resolve, reject: reject, callback: callback };
                port.postMessage(JSON.stringify(r))
                resolve(true);
            })
            return {
                promise,
                unsubscribe: () => {
                    delete this.subscriptions[r.id];
                    port.postMessage(JSON.stringify({ id: r.id, action: 'unsubscribe', }))
                }
            };
        }
        return { promise: new Promise(resolve => resolve(false)) };
    }
}