import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import { RecordVoiceOverIcon, DirectionsRunIcon, VolumeUpIcon, StopIcon, PlayIcon, PauseIcon, FaceIcon } from '../services/icons';
import { WaveFile } from 'wavefile';
import { Range } from 'react-range';

export default class ReadAloud extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.app = window.app;
        this.mute = false;
        this.reading = false;
        this.range = [];
        window.readaloud = this;
        this.isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
        this.si = {
            rate: 1,
            pitch: 1,
            volume: 0.3,
            voice: this.isIOS ? 'Daniel' : 'Alex'
        }
        this.paused = true;
        this.init();
        this.current = new Proxy(JSON.parse(localStorage.getItem('lastpos') || '{}'), {
            get: (target, key) => {
                return target[key];
            },
            set: (target, key, value) => {
                if (target[key] !== value) {
                    target[key] = value;
                    localStorage.setItem('lastpos', JSON.stringify(target));
                }
                return true;
            }
        })
    }

    init = () => {
        if (window.speechSynthesis)
            this.setSpeechSynthesis();
    }

    setSpeechSynthesis = () => {
        this.synth = window.speechSynthesis;
        this.synthInstance = new SpeechSynthesisUtterance();
        this.synthInstance.rate = this.si.rate;
        this.synthInstance.pitch = this.si.pitch;
        this.synthInstance.volume = this.si.volume;
        this.synthInstance.voice = this.synth.getVoices().filter((voice) => { return voice.name === this.si.voice; })[0];
    }

    setReading = (x) => {

        this.reading = x;
        if (this.cb) {
            this.cb(x)
        }

    }
    audioplay = () => {
        let duration = 10 * 60;
        this.wav = new WaveFile();
        let sampleRate = 8000; //44100
        this.wav.fromScratch(1, sampleRate, '8', Array.from(Array((sampleRate * duration))).map(x => 0)); //44100
        // Create a Blob from the ArrayBuffer
        let blob = new Blob([this.wav.toBuffer()], { type: 'audio/wav' });
        // Create a URL for the Blob
        let blobUrl = URL.createObjectURL(blob);
        let audio = new Audio(blobUrl);
        audio.crossOrigin = 'anonymous';
        audio.play();
        audio.onerror = () => {
            console.log(audio.error);
        }
        audio.onplay = () => {
            console.log('audio playing');
            if (this.paused) {
                this.resume();
            }
        }
        audio.onpause = () => {
            console.log('audio paused');
            this.pause();
        }
        return audio;

    }
    stop = () => {
        try {
            this.setReading(false);
            this.synth.cancel();
            this.readid = undefined;
            this.killController();
            let mark = this.doc.getElementById('hilite');
            mark.style.visibility = 'hidden';
            this.paused = true;
            if (this.audio) {
                this.audio.pause();
                this.audio.currentTime = 0;
                delete this.audio;
            }
        } catch (error) {

        }
        this.forceUpdate();
    }
    pause = () => {
        this.paused = true;
        this.synth.pause();
        try {
            if (this.audio) {
                this.audio.pause();
                // delete this.audio;
            }
        } catch (error) {

        }
        this.forceUpdate();

    }
    resume = () => {
        this.paused = false;
        this.synth.resume();
        try {
            if (this.audio)
                this.audio.play();
        } catch (error) {
            this.audio = this.audioplay();
        }
        this.forceUpdate();

    }

    readOutLoud = (target, offset, topNode, duration) => {
        this.doc = target.ownerDocument;
        if (!this.reading) {
            this.setReading(true);
            this.read(target, offset, topNode);
            this.audio = this.audioplay();
        } else {
            this.stop();
        }
        this.paused = false;
        this.forceUpdate();
    }

    read = (node, offset, topNode, cb) => {
        this.readid = Math.random().toString(36).substring(2, 15);
        this.paused = false;
        if (this.reading) {
            this.synth.cancel();

            if (window.safari || this.isIOS) {
                setTimeout(() => {
                    if (this.timeoutResumeInfinity) clearTimeout(this.timeoutResumeInfinity);
                    if (this.cb) {
                        this.cb(false);
                        if (cb)
                            this.cb = cb;
                        this.cb(true);
                    }
                    this.WalkTheNode(this.readid, node, offset)
                }, window.safari || this.isIOS ? 150 : 0);
            } else {
                if (this.timeoutResumeInfinity) clearTimeout(this.timeoutResumeInfinity);
                if (this.cb) {
                    this.cb(false);
                    if (cb)
                        this.cb = cb;
                    this.cb(true);
                }
                this.WalkTheNode(this.readid, node, offset)
            }

        }
        else {
            if (cb) this.cb = cb;
            this.setReading(true);
            this.WalkTheNode(this.readid, node, offset, undefined, topNode)
        }
    }
    WalkTheNode = (readid, node, offset, skipChild, orginalNode) => {
        if (node === undefined || !node) return;
        if (node.isSameNode(orginalNode)) {
            this.stop();
            return
        };

        let next;
        let skip = undefined;
        if (node.dataset && node.dataset.skip) {
            skipChild = true;
        }
        if (node.tagName !== 'STYLE' && node.hasChildNodes() && !skipChild) {
            next = node.childNodes[0];
        } else {
            next = node.nextSibling;
            if (next) {

            } else {
                next = node.parentNode;
                skip = true;
            }
        }
        if (node.nodeType === 3 && !skipChild) { // NODE_TEXT
            this.speakingNode(readid, node, offset, next, skip, orginalNode || node)
        } else {
            this.WalkTheNode(readid, next, offset, skip, orginalNode || node)
        }
    }


    speakingNode = (readid, node, offset, next, skip, orginalNode) => {

        this.synthInstance.text = node.nodeValue.substring(offset || 0);
        this.synthInstance.rate = this.si.rate;
        this.synthInstance.pitch = this.si.pitch;
        this.synthInstance.volume = this.si.volume;
        this.synthInstance.voice = this.synth.getVoices().filter((voice) => { return voice.name === this.si.voice; })[0];
        let paused = false;
        let resumeInfinity = () => { // to fix the bug in chrome where with non default voice it stops mid sentence in long sentences
            if (readid === this.readid) {
                this.synth.resume();
                this.timeoutResumeInfinity = setTimeout(resumeInfinity, 3000);
            }
        }
        if (window.chrome) {
            this.synthInstance.onstart = (event) => {
                //console.log('onstart', paused);
                resumeInfinity();
            };
        }
        this.synthInstance.onend = (e) => {
            //console.log('onend', paused);
            if (window.chrome) clearTimeout(this.timeoutResumeInfinity);
            if (this.reading && readid === this.readid) this.WalkTheNode(readid, next, undefined, skip, orginalNode)
        }
        this.synthInstance.onerror = (e) => {
            console.log(e);

        }
        this.synthInstance.onpause = (e) => {

            if (this.paused) {
                if (window.chrome) clearTimeout(this.timeoutResumeInfinity);
                //console.log('Speech paused after ' + e.elapsedTime + ' milliseconds.');
            }
            else {
                this.synth.cancel();
                if (window.chrome) {
                    clearTimeout(this.timeoutResumeInfinity);
                    this.speakingNode(readid, node, offset, next, skip, orginalNode);
                } else {
                    setTimeout(() => {
                        this.speakingNode(readid, node, offset, next, skip, orginalNode);
                    }, 1000);
                }
            }



        }
        this.synthInstance.onresume = (e) => {
            if (this.paused) {
                if (window.chrome) {
                    this.synthInstance.onstart = (event) => {
                        // console.log('onstart', paused);
                        resumeInfinity();
                    };
                }
                this.paused = false;
            }
        }
        this.synthInstance.onboundary = (e) => {
            if (this.reading && readid === this.readid) {
                let charLength = e.charLength;
                if (!charLength) {
                    charLength = e.target.text.substring((offset || 0) + e.charIndex).indexOf(' ')
                    if (charLength === -1) {
                        charLength = e.target.text.substring((offset || 0) + e.charIndex).length
                    }
                }
                this.hilite(node, (offset || 0) + e.charIndex, node, (offset || 0) + e.charIndex + charLength);
            }
        }

        this.synth.speak(this.synthInstance);
    }
    focusNode = (node) => {

        while (!node.parentNode?.dataset?.chapter) {
            node = node.parentNode;
        }
        this.current.chapter = node.parentNode.dataset.chapter;
        this.current.para = node.dataset.para;

        let currentStyle = node.ownerDocument.getElementById('current');
        currentStyle.innerText = `
[data-chapter="${this.current.chapter}"] [data-para="${this.current.para}"]{
    position: relative;
}
[data-chapter="${this.current.chapter}"] [data-para="${this.current.para}"]:before {
content: '';
position: absolute;
right: 102%;
width: 5px;
top: 0px;
bottom: 0px;
background: #b2b0b0;
}`

    }
    hilite = (sn, so, en, eo) => {
        try {
            if (so < 0 || eo < 0) return;

            let mark = this.doc.getElementById('hilite');
            let scroller = this.doc.getElementById('infinitescroller');

            this.focusNode(sn);
            let range = this.doc.createRange();
            range.setStart(sn, so);
            range.setEnd(en, eo);
            let gbc = range.getBoundingClientRect();
            mark.style.top = (scroller.scrollTop + gbc.top - 2) + 'px';
            mark.style.left = gbc.left + 'px';
            mark.style.width = gbc.width + 'px';
            mark.style.height = '3px';//gbc.height + 'px';
            mark.style.visibility = 'visible';
            scroller.scrollTop = (scroller.scrollTop + gbc.top - 2 - (window.innerHeight / 2))
            // mark.scrollIntoView({ behaviour: 'smooth', block: 'center' });

        } catch (e) {
            console.log(e, sn, so, en, eo)
        }

    }

    showController = () => {

        let div = document.getElementById('voicecontroller');
        if (!div) {
            div = document.createElement('div');
            div.id = 'voicecontroller';
            document.body.appendChild(div);
        }
        this.root = createRoot(div)
        this.root.render(<Controller ssu={this.synthInstance} si={this.si} synth={this.synth} />)
    }
    killController = () => {
        try {
            this.root.unmount();
        } catch (e) {

        }
    }
    readFromHere = (sel, target) => {
        let offset = (sel) => {
            try {
                let substr = sel.focusNode.nodeValue.substring(0, sel.focusOffset)
                return substr.lastIndexOf(' ');
            } catch (e) {
                return sel.focusOffset
            }
        }
        this.readOutLoud(sel.focusNode, offset(sel), target)
    }
    setCurrent = (sel) => {
        let info = this.getchapterinfo(sel.focusNode);
        this.setState({
            current: sel, ...info, //audio: audio 
        })
    }
    getchapterinfo = (n) => {
        if (n.parentNode);
        {
            let dur = n.parentNode.getAttribute('data-duration');
            if (dur) {

                let headings = n.parentNode.querySelectorAll("h1, h2, h3, h4, h5, h6");
                // console.log(headings)
                let title = headings[0] ? (headings[0].innerText + (headings[1] ? ('.' + headings[1].innerText) : '')) : n.parentNode.getAttribute('title');
                return { duration: dur, title: title };
            } else {
                return this.getchapterinfo(n.parentNode);
            }
        }
    }
    render() {
        return <div style={{ position: 'absolute', bottom: 0, scrollSnapAlign: 'center', scrollSnapStop: 'always', flexShrink: 0, backgroundColor: 'var(--bg)', margin: '0px', justifyContent: 'flex-start', overflow: 'hidden', padding: 5, width: '100%', gap: 10,  borderTop: '2px solid grey' }}>
            <div id={'voicecontroller'} style={{ justifyContent: 'flex-end', maxWidth: 300,  }}>
                <Controller ssu={window.readaloud.synthInstance} si={window.readaloud.si} synth={window.readaloud.synth} p={window.readaloud} paused={this.paused}
                    play={() => {
                        console.log('play')
                        if (!window.readaloud.reading) {
                            let target = this.props.iframe.current.contentWindow.document.getElementById('infinitescroller')
                            if (this.state.current) {
                                this.readFromHere(this.state.current, target);
                            } else {
                                this.readOutLoud(target);
                            }
                        } else {
                            window.readaloud.resume();
                        }
                    }}
                    pause={() => {
                        console.log('pause')
                        window.readaloud.pause();
                    }}
                />
            </div>
        </div>
    }

}

class Controller extends Component {
    constructor(props) {
        super(props);
        this.state = { ...props.si, voices: [] }

    }
    componentDidMount() {
        window.speechSynthesis.onvoiceschanged = () => {
            console.warn('voices are ready');
            this.setState({ voices: window.speechSynthesis.getVoices() })
        };
    }
    render() {
        let { volume,
            pitch,
            rate,
            voice, } = this.state;
        let props = this.props;
        let voices = window.speechSynthesis.getVoices();

        return <div style={{ flexDirection: 'column', gap: 20, padding: 10, borderRadius: 5 }}>

            <div style={{ flexDirection: 'row', height: 70, margin: '0px 5px', flexShrink: 0, gap: 5, }}>
                <div style={{ margin: '0px 5px', flex: 1, gap: 10, background: 'whitesmoke', flexDirection: 'row', fontSize: '16px', justifyContent: 'center', alignItems: 'center', borderRadius: 5, cursor: 'pointer', boxShadow: 'var(--neo)' }}
                    onClick={() => {
                        if (this.props.paused) {
                            props.play();
                        } else {
                            props.pause();

                        }
                    }}
                >
                    {this.props.paused ? <PlayIcon className='button' style={{ margin: 'auto', fontSize: '24px', width: 40, verticalAlign: 'middle', color: 'black' }} /> : <PauseIcon className='button' style={{ margin: 'auto', fontSize: '24px', width: 40, verticalAlign: 'middle', color: 'black' }} />}
                </div>
                <div style={{ margin: '0px 5px', flex: 1, gap: 10, background: 'whitesmoke', flexDirection: 'row', fontSize: '16px', justifyContent: 'center', alignItems: 'center', borderRadius: 5, cursor: 'pointer', boxShadow: 'var(--neo)' }}
                    onClick={() => { props.p.stop(); }}>
                    <StopIcon className='button' style={{ margin: 'auto', fontSize: '24px', width: 40, verticalAlign: 'middle', color: 'black' }} />
                </div>
                <div style={{ margin: '0px 5px', flex: 1, gap: 10, background: 'whitesmoke', flexDirection: 'row', fontSize: '16px', justifyContent: 'center', alignItems: 'center', borderRadius: 5, cursor: 'pointer', boxShadow: 'var(--neo)' }}
                    onClick={() => { this.setState({ expand: !this.state.expand }); }}>
                    <i className='material-icons'>{this.state.expand ? 'expand_less' : 'expand_more'}</i>
                </div>
            </div>
            {this.state.expand ? <React.Fragment>
                <Slider value={volume} min={0} max={1} step={0.05} onChange={(value) => {
                    props.synth.pause();
                    this.setState({ volume: value });
                    props.ssu.volume = value;
                    props.si.volume = value;
                    // props.synth.resume();

                }}>
                    <VolumeUpIcon style={{ margin: 'auto', fontSize: '24px', verticalAlign: 'middle', color: 'black' }} />
                </Slider>
                <Slider value={pitch} min={0} max={2} step={0.05} onChange={(value) => {
                    props.synth.pause();
                    this.setState({ pitch: value });
                    props.ssu.pitch = value;
                    props.si.pitch = value;
                    //props.synth.resume();
                }}>
                    P
                </Slider>
                <Slider value={rate} min={0} max={2} step={0.1} onChange={(value) => {
                    props.synth.pause();
                    this.setState({ rate: value });
                    props.ssu.rate = value;
                    props.si.rate = value;
                    //props.synth.resume();
                }}>
                    <DirectionsRunIcon style={{ margin: 'auto', fontSize: '24px', verticalAlign: 'middle', color: 'black' }} />
                </Slider>
                <div style={{ margin: '0px 5px', height: 60, gap: 10, flexDirection: 'row', fontSize: '16px', justifyContent: 'flex-start', alignItems: 'center', overflow: 'hidden' }}
                >
                    <FaceIcon style={{ margin: 'auto', fontSize: '24px', width: 40, verticalAlign: 'middle', color: 'black' }} />
                    <div style={{ display: 'flex', flex: 1, alignSelf: 'stretch' }}>
                        <select style={{ position: 'absolute', inset: 0 }} value={voice}
                            onChange={(e) => {
                                props.synth.pause();
                                this.setState({ voice: e.target.value });
                                props.ssu.voice = voices.filter((voice) => { return voice.name === e.target.value; })[0];
                                props.si.voice = e.target.value;
                                //props.synth.resume();
                            }}
                        >
                            {
                                voices.map((v, i) => <option key={v.name + i} value={v.name} style={{ fontSize: '9px' }}>{v.name}</option>)
                            }
                        </select>
                    </div>

                </div>
            </React.Fragment> : null}

        </div >
    }
}

function Slider(props) {

    return <div style={{ flexDirection: 'row', alignItems: 'center', margin: '0px 5px', padding: '3px', overflow: 'hidden', height: 60, gap: 10 }}>

        <div style={{ width: 40, height: '100%', justifyContent: 'center', alignItems: 'center' }}>
            {props.children}
        </div>
        {/* <input type="range" min={props.min} max={props.max} step={props.step} value={props.value}
            style={{ width: '200px' }}
            onChange={(e) => {
                props.onChange(e.target.value)
            }} /> */}
        <Range
            style={{ display: 'flex' }}
            min={props.min}
            max={props.max}
            step={props.step}
            values={[props.value]}
            onChange={(values) => {
                props.onChange(values[0])
            }}
            renderTrack={({ props, children }) => (
                <div
                    {...props}
                    style={{
                        display: 'flex',
                        ...props.style,
                        height: '6px',
                        width: '100%',
                        margin: 'auto 0px',
                        backgroundColor: '#cccccc'
                    }}
                >
                    {children}
                </div>
            )}
            renderThumb={({ props }) => (
                <div
                    {...props}
                    style={{
                        ...props.style,
                        height: '500%',
                        top: '0px',
                        aspectRatio: 1,
                        borderRadius: '50%',
                        backgroundColor: '#15eccb'
                    }}
                />
            )}
        />
    </div>


}

